import {
  setDeliveryDateErrorFromAddToCart,
  setPersonalizationErrorFromAddToCart,
  setPincodeErrorFromAddToCart,
} from "../../../store/slices/pdpSlice";
import { dateToDMY } from "../../common/deliveryDate";

/**
 *
 * @param {*} string
 * @returns String with first alphabet Capital
 */
export const capitalizeFirstLetter = (string) => {
  return string?.toLowerCase().replace(/^./, (match) => match.toUpperCase());
};

/**
 * This method is to filter out flavour from cake product
 *
 */
export const generateFlavorTypeList = (variantProducts) => {
  const uniqueFlavors = [];

  variantProducts?.forEach((variant) => {
    const key = Object.keys(variant)?.[0];

    if (key) {
      const [, , flavor] = key.split("|");
      if (flavor) {
        const trimmedFlavor = flavor.trim();
        if (!uniqueFlavors.includes(trimmedFlavor)) {
          uniqueFlavors.push(trimmedFlavor);
        }
      }
    }
  });

  return uniqueFlavors;
};

/**
 *
 * @param {*} pincode
 * @param {*} deliveryDetailsData
 * @param {*} userPersonalization
 * @param {*} dispatch
 * @returns
 */
export const validateAllRequiredFieldsToAddToCart = (
  pincode,
  deliveryDetailsData,
  personalizationValidation,
  dispatch,
) => {
  let hasError = false;

  /**
   * @param {string} elementId
   */
  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  if (!pincode) {
    scrollToElement("location_lock");
    dispatch(setPincodeErrorFromAddToCart(true));
    hasError = true;
  } else if (deliveryDetailsData) {
    scrollToElement("delivery_date_slot");
    dispatch(setDeliveryDateErrorFromAddToCart(true));
    hasError = true;
  } else if (!personalizationValidation) {
    scrollToElement("personalization_section");
    dispatch(setPersonalizationErrorFromAddToCart(true));
    hasError = true;
  } else if (!hasError) {
    dispatch(setPincodeErrorFromAddToCart(false));
    dispatch(setDeliveryDateErrorFromAddToCart(false));
    dispatch(setPersonalizationErrorFromAddToCart(false));
  }

  return hasError;
};

/**
 * This method is used to check the product is digital or not
 *
 * @returns {boolean}  will return true or false
 */
export const checkIsDigitalProduct = (currentItem) => {
  if (currentItem?.shippingDisplayOptions && !currentItem?.shippingDisplayOptions.showPincodeOrCity) {
    return Object.keys(currentItem?.shippingDisplayOptions?.defaultGeoDetails).length > 0;
  }
  return false;
};

/**
 * This method is used to check that product is personalized type or not
 *
 * @param {object} product
 * @returns {boolean} is express product or not .
 */
export const isPersonalizedProduct = (product) => product?.personalizedInfo?.personalizedProduct;

/**
 * Function to render a bottom border line, with visibility based on screen size.
 * The border is hidden on mobile by default and shown on desktop, or vice versa based on the desktopVisibility argument.
 *
 * @param {boolean} [desktopVisibility=false] - Determines the visibility of the border on desktop screens.
 *                                              If true, the border is hidden on desktop and shown on mobile.
 *                                              If false, the border is shown on desktop and hidden on mobile.
 * @returns {JSX.Element} A div element with a bottom border and background color.
 */
export const borderLine = (desktopVisibility = false) => (
  <div className={`border-b-3 border-lightGrey bg-grey-100 ${desktopVisibility ? "hidden md:block" : "md:hidden"}`} />
);

/**
 * Generates a multi-order date range.
 * @param {Date} startDateString - The start date for the range.
 * @param {number} noOfDays - Number of days in the range.
 * @param {string} type - Frequency type (e.g., "DAILY").
 * @returns {Array} Array of formatted dates.
 */
export const getMultiOrderDateRange = (startDateString, { noOfDays, type }) => {
  if (!noOfDays || !startDateString || type !== "DAILY") return [];

  // Convert startDate string to Date object
  const [day, month, year] = startDateString.split("-").map(Number);
  const startDate = new Date(year, month - 1, day);

  // Generate the date range
  return Array.from({ length: noOfDays }, (_, index) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + index);
    return dateToDMY(date);
  });
};
