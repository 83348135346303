import { parseCookies } from "nookies";

/**
 * Checks if either the fnpci or fnpli cookie exists and is set to "t".
 *
 * @returns {boolean} True if either cookie exists with value "t", otherwise false.
 */
export const checkCartAndLoginCookie = () => {
  const { fnpci, fnpli } = parseCookies();
  return fnpli === "t" || fnpci === "t";
};
