import isEmpty from "lodash.isempty";
import get from "lodash.get";
import isUndefined from "lodash.isundefined";
import { FnpIND, FnpSG, FnpUAE, FnpDesktopIND, FnpDesktopSG, FnpDesktopUAE } from "./omniture-consts";
import isMobile, { removeHashFromURL, removeURLParameter } from "../../utils/common";

const DEFAULT_TAG = "Clear Filter";

/**
 * This is the function that checks the data for the product list city,occasion,recipient
 *
 * @param {string} tagData data coming from omniture api
 * @param {string} urlData data coming from the url params
 * @returns {string} a string of required data is returned
 */
const dataChecker = (tagData, urlData) => {
  if (tagData === DEFAULT_TAG) {
    return urlData || "";
  }
  return tagData || "";
};

/**
 * This method is taking inputs from the state and creates the product and addons data object
 *
 * @param {string} addon - addons present or Not
 * @param {string} baseProductId - base product ID
 * @param {string} price - price of the product
 * @param {string} productCategory - category of the product
 * @param {string} productId - product id
 * @param {string} productName - name of the product
 * @param {string} quantity - number of quantity selected
 * @param {string} shipping - shipping charges
 *
 * @returns {object} data - returns the product and addon data object
 */

/**
 *This method creates the custData object inside the digitalData
 *
 * @param {string} customerId - customerId of the user
 * @param {string} emailId - emailId of the user
 * @param {string} mobNo - mobNo of the user
 * @returns {object} data - returns the customer data object
 */
const stateToUser = (customerId, emailId, mobNo) => {
  const data = {
    customerID: customerId || "",
    emailID: emailId || "",
    mobNo: mobNo || "",
  };
  return isEmpty(data) ? undefined : data;
};

/**
 * This method is evaluating each product item inside productList and returning product data
 *
 * @param {object} item - productItem retrieved from ProductList in state
 * @param {string} categoryId - categoryId for the searched category in the plp page
 * @param {number} pos -the position of the particular product
 * @returns {object} product - product object
 */
const productListToProduct = (item, categoryId, pos) => {
  const product = {};
  if (!isUndefined(item)) {
    product.productid = item.productId;
    product.productprice = get(item.price, "mrp").toString();
    product.productdelivery = item.earliestDeliveryDate || "";
    product.productflag = Array.isArray(item.productAttributes)
      ? item.productAttributes[0]
      : item.productAttributes || "NA";
    product.productposition = Math.round((pos + 1) / 4);
    product.productdiscount = get(item.price, "listPrice") ? item.price.listPrice - get(item.price, "mrp") : 0;
    product.productcategory = categoryId;
  }
  return product;
};

/**
 * This method creates the productString object which can be used inside the digitalData global object.
 * It iterates over the product list and push the data.
 *
 * @param {object} productList - list of products in plp page retrieved from the state
 * @param {string} categoryId - categoryId retrieved from the state
 * @returns {object} data - returns the productString data object
 */
const stateToProduct = (productList, categoryId) => {
  const data = [];
  productList?.slice(0, 12).forEach((item, index) => {
    data.push(productListToProduct(item, categoryId, index));
  });
  return isEmpty(data) ? undefined : data;
};

/**
 * This method is taking inputs from the state and creating the category object
 *
 * @param {object} categoryDesc - categoryDescription object retrieved from the state
 * @param {string} catalogId - specifies region, default value is "india"
 * @param {object} tagDetails - tag details for city, occasion and recipient
 * @param {object} searchOptions -  search options used by the customer to retrieve the products
 * @returns {object} data - returns the category data object
 */
const stateToCategory = (categoryDesc, catalogId, tagDetails, searchOptions) => {
  let urlRecipient = searchOptions && searchOptions.get("RECIPIENT_TAGS");
  urlRecipient = (urlRecipient && urlRecipient.replace("for-", "")) || "";
  const urlCity = (searchOptions && searchOptions.get("CITY_TAGS")) || "";
  const urlOccasion = (searchOptions && searchOptions.get("OCCASION_TAGS")) || "";
  const productType = tagDetails?.PRODUCT_TYPE?.display || "";
  const city = tagDetails?.CITY?.display || "";
  const occasion = tagDetails?.OCCASION?.display || "";
  const recipient = tagDetails?.RECIPIENT?.display || "";
  const data = {
    categoryType: categoryDesc && categoryDesc.productCategoryId,
    city: dataChecker(city, urlCity),
    occasion: dataChecker(occasion, urlOccasion),
    productType: productType !== DEFAULT_TAG ? productType : "",
    recipient: dataChecker(recipient, urlRecipient),
  };
  if (catalogId !== undefined) {
    data.catalogId = catalogId;
  }
  return isEmpty(data) ? "" : data;
};

/**
 * This method sets up the event that was triggered.
 * Example events are "product listing"
 *
 * @param {string} eventName - Event name signifies the page on which event is triggered.
 * @returns {object} event object
 */
const setEvent = (eventName) => ({ eventName });

/**
 * This method returns the brand as per the catalogId
 *
 * @param {string} rootGeoId - rootGeoId fetched from the store
 * @returns {string} brand
 */
const calculateBrand = (rootGeoId) => {
  switch (rootGeoId) {
    case FnpIND.countryId:
      return isMobile() ? FnpIND.brand : FnpDesktopIND.brand;
    case FnpSG.countryId:
      return isMobile() ? FnpSG.brand : FnpDesktopSG.brand;
    case FnpUAE.countryId:
      return isMobile() ? FnpUAE.brand : FnpDesktopUAE.brand;
    default:
      return isMobile() ? FnpIND.brand : FnpDesktopIND.brand;
  }
};

/**
 * This method is taking inputs from the state and creates the page data object
 *
 * @param {string} rootGeoId - rootGeoId
 * @param {string} channel - current page in the m-site (eg: plp,home)
 * @param {string} catalogId - specifies region (default value: india)
 * @param {string} pageCategory - category of the page
 * @param {Array} levelOfData - level of data searched under the categories
 * @param {string} loginStatus - login status of the user
 * @param {object} searchOptions - search options for which the product was searched
 * @param {string} pageLevel - pageLevel for pages , other than plp
 * @returns {object} data - returns the page data object
 */
const stateToPage = (
  rootGeoId,
  channel,
  catalogId,
  pageCategory,
  levelOfData,
  loginStatus,
  searchOptions,
  pageLevel,
) => {
  const brand = calculateBrand(rootGeoId);

  // Construct pageName based on levelOfData and pageLevel
  let pageName = `${brand}:${channel}:${catalogId}`;
  if (Array.isArray(levelOfData)) {
    pageName += `:${levelOfData.join(":")}`;
  } else if (!isEmpty(pageLevel)) {
    pageName += `:${pageLevel}`;
  }

  // Determine the destination URL
  let destinationURL = window.location.href;
  if (!isEmpty(searchOptions) && get(searchOptions.params, "promo")) {
    destinationURL = removeURLParameter(destinationURL, "promo");
  }
  destinationURL = removeHashFromURL(destinationURL);

  const data = {
    brand,
    destinationURL,
    channel,
    pageName,
    hierarchy: Array.isArray(levelOfData) ? levelOfData.join(":") : levelOfData || "",
    pageCategory,
    loginStatus,
    site: "react2-R2",
    platform: "new web",
  };

  return isEmpty(data) ? undefined : data;
};

/**
 * This method sets up the search object when search event was triggered.
 *
 * @param {string} eventName - Event name signifies the page on which event is triggered.
 * @param {string} searchResults - number of items for which searh was listed
 * @param {string} searchTerm - search query string
 * @returns {object} search object
 */
const stateToSearch = (eventName, searchResults, searchTerm) => {
  const data = {
    eventName,
    searchResults,
    searchTerm,
  };
  return isEmpty(data) ? undefined : data;
};

/**
 * This method creates the productString object which can be used inside the digitalData global object.
 * It iterates over the cartData array and push the data.
 *
 * @param {object} cardData - list of products in added in the cart page
 * @returns {object} data - returns the productString data object
 */
const stateToCart = (cardData) => ({
  productString: isEmpty(cardData) ? "" : cardData,
});

/**
 * This method creates the productString object which can be used inside the digitalData global object.
 * It iterates over the cartData array and push the data.
 *
 * @param {string} prodId - Product ID
 * @returns {object} data - returns the productString data object
 */
const stateToCartRemove = (prodId) => ({
  productString: {
    productId: prodId.length === 0 ? "" : prodId,
  },
});

export {
  stateToUser,
  stateToProduct,
  productListToProduct,
  setEvent,
  stateToCategory,
  calculateBrand,
  stateToPage,
  stateToSearch,
  stateToCart,
  stateToCartRemove,
};
