/* eslint-disable max-lines */
import get from "lodash.get";
import isUndefined from "lodash.isundefined";
import {
  setEvent,
  stateToCart,
  stateToCategory,
  stateToPage,
  stateToProduct,
  stateToSearch,
  stateToUser,
} from "./omniture-mapper";
import { dataLayerVarName, channel, pageCategories, paths, loggedInStatus } from "./omniture-consts";
import * as consts from "./omniture-consts";
import getCatalogueAndCategory from "../../utils/extractCatalogueAndCategoryinfo";
import { capitalizeFirstLetter } from "../../utils/pdp/common";
import { checkCartAndLoginCookie } from "../../utils/fus";
import { PageTypes } from "../../utils/fnpPageType";
import CookieUtil from "../../utils/storage/cookieUtil";
import { AUTO_TRIGGER_LOCK } from "../../constants/common/locationFieldConstants";
import isMobile from "../../utils/common";
import { COUNTRIES } from "../../constants/common/common";
import isEmpty from "lodash.isempty";

let stateInUtility;
let catalogue;
let rootGeoId;
/**
 * Utility function set state in a global variable
 *
 * @param {object} stateForUtility info related to user login.
 */
function setStateInOmnitureUtility(stateForUtility) {
  stateInUtility = stateForUtility;
  catalogue = get(stateInUtility, "appConfigs.catalogId") || COUNTRIES.INDIA;
  rootGeoId = get(stateInUtility, "appConfigs.rootGeoId") || COUNTRIES.INDIA;
}

const defaultState = {
  catalogue: catalogue || COUNTRIES.INDIA,
  channel: channel.HOME,
  pageCategory: pageCategories.HOME,
};

/**
 * Utility function to get login status
 *
 * @param {object} userLoginInfo info related to user login.
 * @returns {string} login status.
 */
const getLoginStatus = (userLoginInfo) => {
  const loginStatus = userLoginInfo?.loggedin ? loggedInStatus.LOGGED_IN : loggedInStatus.GUEST;
  return loginStatus;
};

/**
 * This method sets up the user/custData configuration.
 *
 * @param {object} userLoginInfo - user logged-in details fetch from store
 *
 * @returns {object} userData
 */
function getUser(userLoginInfo) {
  const userData =
    userLoginInfo && userLoginInfo.loggedin
      ? stateToUser(userLoginInfo.oracleContactId1, userLoginInfo.userLoginId, userLoginInfo.phone)
      : stateToUser();

  return userData;
}

/**
 * This is a utility method to set page load event data in adobeDataLayer
 *
 * @param {object} data - data object w.r.t specified path param
 */
function setPageLandedData(data) {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const isLoggedIn = userLoginInfo?.loggedin ?? checkCartAndLoginCookie();
  const validCustId = data.custdata.emailID || data.custdata.mobNo;
  const enableDataPush = (!isLoggedIn && !validCustId) || (isLoggedIn && validCustId);
  if (enableDataPush) {
    window[dataLayerVarName].push({
      event: "landed",
      eventype: "landed",
      ...data,
    });
  }
}

/**
 * This method captures common page data for adobeDataLayer
 *
 * @param {string} pageChannel page channel
 * @param {string} catalogId page catalog id
 * @param {string} pageCategory page category
 * @param {Array} levelOfData page level of data
 * @param {object} searchOptions page search options
 * @param {string} pageLevel page level
 */
const setCommonPageLandedData = (
  pageChannel = "",
  catalogId = "",
  pageCategory = "",
  levelOfData = "",
  searchOptions = "",
  pageLevel = "",
) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);
  const gateData = get(stateInUtility, "gateDetails.gateData");
  const lastVisitedPincode = get(stateInUtility, "gateDetails.lastVisitedPincode");
  const isAutoGateEnabledOnDesktopHome = get(
    stateInUtility,
    "gateDetails.autoGatePopupConfig.isAutoGateEnabledOnDesktopHome",
  );
  const isAutoGateEnabledOnMobileHome = get(
    stateInUtility,
    "gateDetails.autoGatePopupConfig.isAutoGateEnabledOnMobileHome",
  );
  const autoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isHomeAutoGateOpen =
    !autoTriggerLock &&
    ((isMobile() && isAutoGateEnabledOnMobileHome) || (!isMobile() && isAutoGateEnabledOnDesktopHome));
  const pageData = stateToPage(
    rootGeoId,
    pageChannel,
    catalogId,
    pageCategory,
    levelOfData,
    loginStatus,
    searchOptions,
    pageLevel,
  );

  if (lastVisitedPincode) {
    pageData.pincodeStatus = consts.gatePopupTracking.PINCODE_RETAINED;
  }

  const pageLandedData = {
    [paths.CUSTOMER_DATA]: custdata,
    [paths.PAGE]: pageData,
  };

  if (
    window.fnpPageType === PageTypes.HOME &&
    !lastVisitedPincode &&
    isHomeAutoGateOpen &&
    (isAutoGateEnabledOnDesktopHome || isAutoGateEnabledOnMobileHome)
  ) {
    pageLandedData[paths.CATEGORY] = { ...gateData.category };
    pageLandedData[paths.GATE] = { ...gateData.gate };
  }
  setPageLandedData(pageLandedData);
};

/**
 *  This method set common page landed event data
 *
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {string} pageCategory - specifies the category of the page (eg: category for plp page)
 * @param {Array} levelOfData - level of data searched under the categories
 */
const setPageCommonLandedEventData = (
  pageChannel = defaultState.channel,
  catalogId = defaultState.catalogue,
  pageCategory = defaultState.pageCategory,
  levelOfData,
) => {
  setCommonPageLandedData(pageChannel, catalogId, pageCategory, levelOfData);
};

/**
 * This methods returns the AdobeData global object.
 *
 * @returns {object} - returns the AdobeData global object
 */
function getAdobeLayer() {
  return window[dataLayerVarName];
}

/**
 * This method will instantiate the AdobeData global object.
 */
const initializeAdobeData = () => {
  window[dataLayerVarName] = window[dataLayerVarName] || [];
};

/**
 * This method loads up the AdobeData when the application is in product listing page
 *
 * @param {Array} breadcrumbs - array of breadcrumbs
 * @param {object} productLists - contains productList, breadCrumbs, cateory information. This object is fetched from state
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page)
 */
const setProductsListing = async (breadcrumbs, eventName, pageChannel, pageCategory, productLists, catalogId) => {
  const url = new URL(window.location.href);
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }

  const selectedIntlCity = get(stateInUtility, "productsLists.selectedIntlCity");
  const gateData = get(stateInUtility, "gateDetails");
  const isAutoGateEnabledOnDesktopPLP = gateData?.autoGatePopupConfig?.isAutoGateEnabledOnDesktopPLP;
  const isAutoGateEnabledOnMobilePLP = gateData?.autoGatePopupConfig?.isAutoGateEnabledOnMobilePLP;
  const autoTriggerLock = CookieUtil.getCookie(AUTO_TRIGGER_LOCK);
  const isPLPAutoGateOpen =
    !autoTriggerLock &&
    ((isMobile() && isAutoGateEnabledOnMobilePLP) || (!isMobile() && isAutoGateEnabledOnDesktopPLP));

  const productList = productLists?.productsList || [];
  const searchOptions = productLists?.searchOptions || {};
  const slug = url?.pathname?.slice(1, url?.pathname?.length);
  const { category } = getCatalogueAndCategory(slug?.split("/"), url?.searchParams);
  const productType = capitalizeFirstLetter(category.replace("-lp", "")).trim();
  const categoryDesc = {
    productCategoryId: url.pathname.substring(1),
  };
  const levelOfData =
    breadcrumbs &&
    breadcrumbs.slice(1).map((level) => {
      return level.label;
    });
  const tagDetails = {
    CITY: { display: "" },
    RECIPIENT: { display: "" },
    OCCASION: { display: "" },
    PRODUCT_TYPE: { display: productType },
  };
  const pageLevel = levelOfData;
  const loginStatus = getLoginStatus(userLoginInfo);
  const productData = stateToProduct(productList, categoryDesc?.productCategoryId);
  const categoryData = stateToCategory(categoryDesc, catalogId, tagDetails, url?.searchParams);
  const eventData = setEvent(eventName);
  const pageData = stateToPage(
    rootGeoId,
    pageChannel,
    catalogId,
    pageCategory,
    levelOfData,
    loginStatus,
    searchOptions,
    pageLevel,
  );

  if (gateData?.lastVisitedPincode) {
    pageData.pincodeStatus = consts.gatePopupTracking.PINCODE_RETAINED;
  }

  if (!categoryData?.city && catalogId !== COUNTRIES.INDIA) {
    categoryData.city = selectedIntlCity;
  }

  const listingData = {};
  listingData[paths.CATEGORY] = categoryData;
  listingData[paths.CUSTOMER_DATA] = getUser(userLoginInfo);
  listingData[paths.FNP_EVENT] = eventData;
  listingData[paths.PAGE] = pageData;
  listingData[paths.PRODUCT_LIST] = productData;
  if (!gateData?.lastVisitedPincode && isPLPAutoGateOpen) {
    listingData[paths.CATEGORY] = { ...categoryData, ...gateData.gateData.category };
    listingData[paths.GATE] = { ...gateData.gateData.gate };
  }

  setPageLandedData(listingData);
};

/**
 * This method loads up the AdobeData when the application is in product listing page
 *
 * @param {object} productLists - contains productList, breadCrumbs, cateory information. This object is fetched from state
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page)
 */
const setProductsListingInSearch = (productLists, eventName, pageChannel, catalogId, pageCategory) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const loginStatus = getLoginStatus(userLoginInfo);
  const custdata = getUser(userLoginInfo);
  const { searchOptions, total } = productLists ?? { searchOptions: {}, total: "" };
  const qs = !isEmpty(searchOptions) ? get(searchOptions, "qs") : "";

  const searchData = stateToSearch(eventName, total, qs);
  const pageData = stateToPage(rootGeoId, pageChannel, catalogId, pageCategory, qs, loginStatus, searchOptions, "");

  const searchPageData = {
    [paths.CUSTOMER_DATA]: custdata,
    [paths.PAGE]: pageData,
    [paths.SEARCH]: searchData,
  };

  setPageLandedData(searchPageData);
};
/**
 *  This method loads up the AdobeData when the application is in cart preview page
 *
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page)
 */
const setCartPreview = async (eventName, pageChannel, catalogId, pageCategory) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const cartItems = get(stateInUtility, "cartDetails.cartItems");
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);
  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }
  const cartList = stateToCart(cartItems);
  const eventData = setEvent(eventName);
  const pageData = stateToPage(rootGeoId, pageChannel, catalogId, pageCategory, "", loginStatus, "", "");
  const pageLandeddata = {
    custdata,
    fnpevent: eventData,
    cartdata: cartList,
    page: pageData,
  };
  if (cartItems) {
    setPageLandedData(pageLandeddata);
  }
};
/**
 *  This method loads up the AdobeData when the application is in cart preview page
 *
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page)
 */
const setLoginData = async (eventName, pageChannel, catalogId) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const validUser = get(stateInUtility, "validID");
  const rootGeoId = get(stateInUtility, "appConfigs.rootGeoId");
  const cartItems = get(stateInUtility, "checkout.cartItems");
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);
  const isRegister = loginStatus === "guest" && validUser.exists === false;
  const pageCategory = isRegister ? pageCategories.REGISTER : pageCategories.LOGIN;
  let finalPageName = pageCategory;

  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }
  const cartList = stateToCart(cartItems);
  const eventData = setEvent(eventName);
  const pageData = stateToPage(rootGeoId, pageChannel, catalogId, pageCategory, "", loginStatus, "", finalPageName);
  const pageLandeddata = {
    [paths.CUSTOMER_DATA]: custdata,
    [paths.FNP_EVENT]: eventData,
    [paths.PRODUCT_LIST]: cartList,
    [paths.PAGE]: pageData,
  };

  if (!userLoginInfo?.loggedin && catalogId) {
    setPageLandedData(pageLandeddata);
  }
};
/**
 * This method sets the Adobe data objects for all plp svn pages and of
 * International home pages.
 *
 * @param {Array} breadcrumbs - breadcrumbs data for svn plp
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} pageCategory - specifies category of the page like for plp its "category"
 */
const setPlpMicroData = async (breadcrumbs, eventName, pageChannel, pageCategory) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }
  const { rootCatalogId, catalogId } = get(stateInUtility, "appConfigs");
  const { category } = getCatalogueAndCategory(window.location.pathname, rootGeoId);

  const levelOfData =
    breadcrumbs &&
    breadcrumbs.slice(1).map((level) => {
      return level.label;
    });
  const loginStatus = getLoginStatus(userLoginInfo);
  const productType = capitalizeFirstLetter(category?.join(""));
  const pageData = stateToPage(rootGeoId, pageChannel, catalogId, pageCategory, levelOfData, loginStatus, "", "");
  const isInternational = rootCatalogId !== catalogId;
  const eventData = setEvent(eventName);
  const plpMicroData = {};
  if (!isInternational) {
    const categoryData = {
      catalog: catalogue,
      categoryType: category.join(""),
      city: "",
      occasion: "",
      productType,
      recipient: "",
    };

    plpMicroData[paths.CATEGORY] = categoryData;
    plpMicroData[paths.PRODUCT_LIST] = [];
  }
  plpMicroData[paths.CUSTOMER_DATA] = getUser(userLoginInfo);
  plpMicroData[paths.FNP_EVENT] = eventData;
  plpMicroData[paths.PAGE] = pageData;
  setPageLandedData(plpMicroData);
};

/**
 * This method loads up the AdobeData when the application is in product description page
 *
 * @param {object} productDetail - contains all the data related to product
 * @param {string} eventName - specifies the event triggered within the application (eg : "product view")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home, pdp)
 * @param {string} catalogIdValue - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page, pdp page)
 * @param {string} isProductStockAvailable - specifies the value that the product is available in stock or not Y if its in stock
 * @param {string} variantProductId - specifies the currently selected variant product id
 * @param {boolean} isMobile - specifies the mobile or desktop
 */
const setProductData = async (
  productDetail,
  eventName,
  pageChannel,
  catalogIdValue,
  pageCategory,
  isProductStockAvailable,
  variantProductId,
  isMobile,
) => {
  const { primaryCategoryId, catalogId, productName, productPrice, skuCode, productId } = productDetail;
  const url = new URL(window.location.href);
  const productPosition = url.searchParams.get("pos") ? Number(url.searchParams.get("pos")) : 0;
  const cityTags = url.searchParams.get("CITY_TAGS") ?? "";
  const recipientTags = url.searchParams.get("RECIPIENT_TAGS") ?? "";
  const occasionTags = url.searchParams.get("OCCASION_TAGS") ?? "";
  const currentProductPrice = productPrice?.price;
  const productString = isMobile
    ? `sku:${skuCode},name:${productName},price:${currentProductPrice}`
    : `[sku:${skuCode},name:${productName},price:${currentProductPrice}]`;
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const eventData = setEvent(eventName);
  const searchOptions = url;
  const loginStatus = getLoginStatus(userLoginInfo);
  const categoryDesc = { productCategoryId: primaryCategoryId, city: "", occasion: "", productType: "" };
  const searchOptionsArr = {
    CITY: { display: cityTags },
    RECIPIENT: { display: recipientTags },
    OCCASION: { display: occasionTags },
    PRODUCT_TYPE: { display: isMobile ? primaryCategoryId : "" },
  };

  const levelOfData = [productName];

  const categoryData = stateToCategory(categoryDesc, isMobile ? undefined : catalogId, searchOptionsArr, "");
  const pageData = stateToPage(
    rootGeoId,
    pageChannel,
    catalogIdValue,
    pageCategory,
    levelOfData,
    loginStatus,
    searchOptions,
    "",
    "react",
  );

  const finalPDPAdobeData = {
    productCatalog: catalogId,
    productOOS: isProductStockAvailable ? "N" : "Y",
    productPosition,
    productSKU: skuCode,
    productString,
    productTemplate: primaryCategoryId,
    productId: variantProductId || productId,
    baseProductID: productId,
  };

  const pdpData = {};
  pdpData[paths.CATEGORY] = categoryData;
  pdpData[paths.CUSTOMER_DATA] = getUser(userLoginInfo);
  pdpData[paths.FNP_EVENT] = eventData;
  pdpData[paths.PAGE] = pageData;
  pdpData[paths.PRODUCT] = finalPDPAdobeData;
  setPageLandedData(pdpData);
};

/**
 * This is a utility method to set page click event data in adobeDataLayer
 *
 * @param {object} data - contains data
 * @param {object} userLoginInfo - contains user login info data
 * @param {object} pageValues - contains page data
 */
function setOnClickAdobeData(data, userLoginInfo, pageValues, productName) {
  const adobeLayer = getAdobeLayer();
  if (isUndefined(adobeLayer)) initializeAdobeData();

  const adobeDataName = window?.adobe ? adobeLayer?.getState() : {};
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);
  const catalogueId = catalogue || COUNTRIES.INDIA;

  const pageData = stateToPage(
    rootGeoId,
    pageValues?.channel,
    catalogueId,
    pageValues?.pageCategories,
    productName,
    loginStatus,
    "",
    "",
  );

  const pageLandedData = {
    [paths.CUSTOMER_DATA]: custdata,
    [paths.PAGE]: pageData,
  };

  const clickData = {
    ...data,
    link: {
      ...data.link,
      linkPageName: adobeDataName?.page?.pageName ?? window.location.href,
    },
  };

  window[dataLayerVarName].push({
    event: "click",
    eventype: "click",
    ...clickData,
    ...pageLandedData,
  });
}

export {
  initializeAdobeData,
  setProductsListing,
  setProductsListingInSearch,
  setCartPreview,
  setPlpMicroData,
  setProductData,
  setLoginData,
  setStateInOmnitureUtility,
  setPageCommonLandedEventData,
  setPageLandedData,
  getLoginStatus,
  getUser,
  setOnClickAdobeData,
};
