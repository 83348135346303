import { MONTH_NAMES } from "../../../constants/common/common";

/**
 * @param {object} shippingDetails .
 *
 * @returns {object} .
 */
export function getShippingMethodsInStringAndCount(shippingDetails) {
  const shippingMethods = Object.values(shippingDetails)?.map((item) => {
    if (typeof item?.shippingMethodName === "string") {
      return item?.shippingMethodName;
    }

    const itemValue = Object?.values(item);

    return itemValue?.[0]?.shippingMethodName;
  });

  const shippingMethodsString = shippingMethods.join(", ");
  const shippingMethodsCount = shippingMethods.length;

  return {
    methodsString: shippingMethodsString || "",
    methodsCount: shippingMethodsCount || "",
  };
}

/**
 * @param {number} num input number
 * @returns {string} number converted to its ordinal form (e.g., 1st, 2nd, 3rd)
 */
export const getOrdinalSuffix = (num) => {
  if (!Number.isInteger(num)) throw new Error("Input must be an integer number");

  const remainder10 = num % 10;
  const remainder100 = num % 100;

  // Superscript Unicode for "st", "nd", "rd", "th"
  const superscripts = {
    st: "ˢᵗ",
    nd: "ⁿᵈ",
    rd: "ʳᵈ",
    th: "ᵗʰ",
  };

  let suffix =
    remainder100 >= 11 && remainder100 <= 13
      ? superscripts.th
      : superscripts[["th", "st", "nd", "rd"][remainder10] || "th"];

  return `${num}${suffix}`; // ✅ Return formatted string
};

/**
 *  Extract the parts and format
 * @param {*} dateArray
 * @returns formated date for cart
 */
export const formattedDateForCart = (dateArray) => {
  return dateArray
    .map((date) => {
      const [, month, day] = date.split(" ");
      return `${getOrdinalSuffix(parseInt(day))} ${month}`;
    })
    .join(" - ");
};

/**
 * this function check is date passed
 *
 * @param {string} date date
 * @returns {boolean} true or false
 */
export function checkCurrentAndFutureDate(date) {
  const currentDate = new Date();
  const [day, month, year] = date.split("-");
  const formattedBlockedDate = new Date(`${month}/${day}/${year}`);

  currentDate.setHours(0, 0, 0, 0);
  formattedBlockedDate.setHours(0, 0, 0, 0);
  if (formattedBlockedDate.getTime() >= currentDate.getTime()) {
    return true;
  }
  return false;
}

/**
 * this function check the dates list contain current or future date
 *
 * @param {object} dates dates
 * @returns {boolean} true or false
 */
export function getDates(dates) {
  let passedDate = false;
  // eslint-disable-next-line no-unused-expressions
  dates &&
    dates.length > 0 &&
    dates.forEach((date) => {
      if (!passedDate) {
        passedDate = checkCurrentAndFutureDate(date);
      }
    });
  return passedDate;
}

/**
 * Utility method to get ordinals
 *
 * @param {object} date date value
 * @returns {string} ordinal.
 */
export function getSuffix(date) {
  const s = ["th", "st", "nd", "rd"];
  const v = date % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
}

/**
 * This method takes the "-" separated date eg: 07-11-2024 and finds out its delivery day
 * that can be either Today or Tomorrow.
 *
 * @param { string } deliveryDate .
 * @param {boolean} isWithDateOrdinal flag to enable date
 * @returns { string } String in delivery date Today and Tomorrow.
 */
export function getEarliestDeliveryDateString(deliveryDate, isWithDateOrdinal) {
  const inputDate = deliveryDate?.split("-");
  const day = Number(inputDate && inputDate[0]);
  const month = Number(inputDate && inputDate[1]);
  const year = Number(inputDate && inputDate[2]);
  const today = new Date();
  const tomorrow = new Date(today?.getTime() + 24 * 60 * 60 * 1000);
  if (day === today?.getDate() && month === today?.getMonth() + 1 && year === today?.getFullYear()) return "Today";
  if (day === tomorrow?.getDate() && month === tomorrow?.getMonth() + 1 && year === tomorrow?.getFullYear())
    return "Tomorrow";
  if (isWithDateOrdinal) {
    return `${day}${getSuffix(day)} ${MONTH_NAMES[month - 1]}`;
  }
  return deliveryDate;
}

/**  This method is used to calculate percentage off product
 *
 * @param {string} dateTimeStr date time string.
 * @returns {object} date object
 */
export const convertTimestampStringToDate = (dateTimeStr) => {
  let date = 0;
  if (dateTimeStr) {
    let dateTimeParts = dateTimeStr.split(" ");
    const timePart = dateTimeParts[1].split(":");
    dateTimeParts = dateTimeParts[0].split("-");
    date = new Date(dateTimeParts[2], parseInt(dateTimeParts[1], 10) - 1, dateTimeParts[0], timePart[0], timePart[1]);
  }
  return date;
};

/**
 *
 * @param {Array} dates
 * @returns formatted date range
 */
export function formatDateRange(dates) {
  const startDate = new Date(dates[0].split("-").reverse().join("-")); // Convert to YYYY-MM-DD
  const endDate = new Date(dates[dates.length - 1].split("-").reverse().join("-"));

  const options = { day: "2-digit", month: "short" };

  const formattedStart = startDate.toLocaleDateString("en-US", options);
  const formattedEnd = endDate.toLocaleDateString("en-US", options);

  return `${formattedStart} - ${formattedEnd}`;
}

/**
 * This function formats a date object into a human-readable string.
 *
 * @function
 * @param {Date} date - The date object to format.
 * @returns {string} A string representation of the date in the format "DDth MMMM" (e.g. "12th January").
 */
export const formatSelectedDate = (date) => {
  const day = date?.getDate();
  const month = date?.toLocaleString("default", { month: "long" });
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
        ? "nd"
        : day % 10 === 3 && day !== 13
          ? "rd"
          : "th";
  return `${day}${suffix} ${month}`;
};

/**
 * This method converts a given 24-hour format time string into a 12-hour format
 * with "AM" or "PM" appended to the result.
 *
 * @param {string} time24 - The time string in "HH:MM" 24-hour format
 * @returns {string} - The formatted time string in 12-hour format with "AM" or "PM"
 */
export const convertTo12Hour = (time24) => {
  const [hours, minutes] = time24.split(":");
  const AmPm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  return `${hours12}:${minutes.toString().padStart(2, "0")} ${AmPm}`;
};

/**
 * @param {number} currYear current year
 * @param {number} lastNYears array length required
 * @returns {Array} category name
 */
export function getLastNYears(currYear, lastNYears) {
  const years = [];
  for (let i = 0; i <= lastNYears; i += 1) {
    const year = currYear - i;
    years.push({ year });
  }
  return years;
}

/** */
export const calenderIsTodayOrTomorrowDate = (inputDate) => {
  if (inputDate) {
    const [day, month, year] = inputDate.split("-").map(Number);
    const givenDate = new Date(year, month - 1, day);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const isDateToday = givenDate.getTime() === today.getTime();
    const isDateTomorrow = givenDate.getTime() === tomorrow.getTime();

    return { isDateToday, isDateTomorrow };
  }
};

/**
 * This method is used to format the date
 *
 * @param {object} date date object.
 * @param {string} separator format seperator.
 * @returns {string} modified object
 */
export function dateToDMY(date, separator = "-") {
  const d = date?.getDate();
  const m = date?.getMonth() + 1;
  const y = date?.getFullYear();
  return `${d <= 9 ? `0${d}` : d}${separator}${m <= 9 ? `0${m}` : m}${separator}${y}`;
}

/**
 * Checks if the given date (`selectedDate`) is today or tomorrow.
 *
 * @param {Date} selectedDate - The date that needs to be checked.
 * @param {Date} [today=new Date()] - (Optional) The reference date for "today". Defaults to the current date if not provided.
 *
 * @returns {Object} - Returns an object with two boolean properties:
 *    - `isToday`: `true` if `selectedDate` is today's date, otherwise `false`.
 *    - `isTomorrow`: `true` if `selectedDate` is tomorrow's date, otherwise `false`.
 */
export function isDateTodayOrTomorrow(selectedDate, today = new Date()) {
  const tomorrow = new Date(today);
  tomorrow.setDate(today?.getDate() + 1);

  const isDateToday = dateToDMY(today) === dateToDMY(selectedDate);
  const isDateTomorrow = dateToDMY(tomorrow) === dateToDMY(selectedDate);

  return { isDateToday, isDateTomorrow };
}
